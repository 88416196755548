import React from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import NumberBox from "../../elements/NumberBox";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function CatchmentInputOptions(props) {
  const parameters = props.parameters;
  const handleTextBoxOnChange = props.handleTextBoxOnChange;
	const updateParameters = props.updateParameters;
	const [selectedPair, setSelectedPair] = React.useState(1);

  function getExponentLabel(base, exp, extra) {
    return (
      <p style={{ "margin": 0 }}>
        {base}<span style={{ fontSize: "0.7em", verticalAlign: "top" }} >{exp}</span>{extra}
      </p>
    );
  } 

  return (
    <Card>
      <Card.Header>Catchment</Card.Header>
      <Card.Body>
        <Container>
          <Row>
            <Col>
              <Form.Group>
                <Form.Check
                  type="radio"
                  label="Catchment Area & Return Period"
                  name="inputPairsRadios"
                  id="inputPair1"
                  checked={selectedPair === 0}
                  onChange={() => {
                    setSelectedPair(0);
                    updateParameters({
                      ...parameters,
                      discharge: "",
                      duration: "",
                    });
                  }}
                />
                <Form.Check
                  type="radio"
                  name="inputPairsRadios"
                  label="Discharge & Duration"
                  id="inputPair2"
                  checked={selectedPair === 1}
                  onChange={() => {
                    setSelectedPair(1);
                    updateParameters({
                      ...parameters,
                      catchmentArea: "",
                      returnPeriod: "",
                    });
                  }}
                />
                <Form.Check
                  type="radio"
                  name="inputPairsRadios"
                  label="Catchment Area & Discharge"
                  id="inputPair3"
                  checked={selectedPair === 2}
                  onChange={() => {
                    setSelectedPair(2);
                    updateParameters({
                      ...parameters,
                      duration: "",
                      returnPeriod: "",
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="Catchment Area"
                unit={getExponentLabel("km", "2")}
                step="0.1"
                disabled={selectedPair === 1 && parameters.bankHeight !== ""}
                name="catchmentArea"
                value={parameters.catchmentArea}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label="Return Period"
                unit="yrs"
                disabled={selectedPair !== 0}
                name="returnPeriod"
                value={parameters.returnPeriod}
                onChange={handleTextBoxOnChange}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="Discharge"
                unit={getExponentLabel("m", "3", "/s")}
                step="0.1"
                disabled={selectedPair === 0}
                name="discharge"
                value={parameters.discharge}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label="Duration"
                unit="min"
                disabled={selectedPair !== 1}
                name="duration"
                value={parameters.duration}
                onChange={handleTextBoxOnChange}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="Base Flow"
                unit={getExponentLabel("m", "3", "/s")}
                step="0.001"
                name="baseFlow"
                value={parameters.baseFlow}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label="Hydrograph Ascending Factor"
                unit="-"
                step="0.001"
                info="The duration of the ascending hydrograph is equal to the duration / factor"
                name="hydrographAscendingFactor"
                value={parameters.hydrographAscendingFactor}
                onChange={handleTextBoxOnChange}
              />
            </Col>
          </Row>


          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="Erosion Coefficient"
                unit={getExponentLabel("m/Pa", "0.5", "/s")}
                name="erosionCoefficient"
                value={parameters.erosionCoefficient}
                onChange={(event) => { parameters.erosionCoefficientChanged = true; handleTextBoxOnChange(event)} }
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
