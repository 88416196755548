
function convertDatasetToMetrics(dataset) {
	const reducer = (acc, currentValue) => acc + currentValue;
	
	return {
		totalSedimmentVolume: dataset.qsed.reduce(reducer) * 60,
		minTotalSedimmentVolume: dataset.qsedmin.reduce(reducer) * 60,
		maxTotalSedimmentVolume: dataset.qsedmax.reduce(reducer) * 60

	}
}

function convertQSedToDataset(dataset, skipAmount, label, color) {

	return {
		type: 'line',
		fill: false,
		data: dataset.qsed.map(function(e, i) { return { x: i, y: e } }),
		borderColor: color,
		label: label + "Qsed",
		pointRadius: 0,
		yAxisID: 'y1',
		row: 'Qsed',
		col: label,
		borderDash: [2, 5],
		hidden: false
	}
}

export default function getQSedDataset(datasets, skipAmount) {
	
	//Datasets
	const noVeggie = datasets.noVeggie;
	const veggie = datasets.veggie;
	const idealVeggie = datasets.idealVeggie;

	const returnSets = [];
	
	returnSets.push(convertQSedToDataset(noVeggie, skipAmount, "No Vegetation", 'red'));
	returnSets.push(convertQSedToDataset(veggie, skipAmount, "Vegetation" , 'blue'));
	returnSets.push(convertQSedToDataset(idealVeggie, skipAmount, "Ideal Vegetation", 'green'));

	return {
	 datasets: returnSets, metrics: {
		qSednoVeggie: convertDatasetToMetrics(noVeggie),
		qSedveggie: convertDatasetToMetrics(veggie),
		qSedidealVeggie: convertDatasetToMetrics(idealVeggie),
	},
	y1Label: "Qsed [m³/s]"


};
}
