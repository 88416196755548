import React from 'react';
import './App.css';
import ErosionCalculator from './components/ErosionCalculator';
import InputPanel from './components/InputPanel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './components/Header';
import Footer from './components/Footer';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import ErosionTab from './components/tabs/ErosionTab';
import FlowHeightTab from './components/tabs/FlowHeightTab';
import TransportTab from './components/tabs/TransportTab';
import UpdatePWAAlert from './components/updatePWAAlert';
import { isMobile } from "react-device-detect";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Button, Nav } from 'react-bootstrap';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { Chart } from "chart.js";

function App() {

  function getTempRunName() {
    const d = new Date();
    return `BankforNetWeb_Run_${d.getFullYear()}-${d.getMonth()}-${d.getDate()}-${d.getTime()}`;
  }


  const [tabKey, setTabKey] = React.useState("input");

  /*
  // Below is SW from BankforNET
  const [bankforNETParameters, setBankforNETParameters] = React.useState({
    returnPeriod: "",
    catchmentArea: "",
    duration: 2334,
    discharge: 130,
    bankHeightInner: 0.6,
    bankHeightOuter: 0.6,
    channelWidth: 62,
    channelSlope: 0.007,
    innerBankSlope: 85,
    meanChannelInclination: 0.007,
    d50Porosity: 0.3,
    sigmad50nsed: 0.03,
    outerBankSlope: 85,
    bendRadius: 185,
    d50Inner: 27,
    d50InnerSD: 2.7,
    d50Outer: 27,
    d50OuterSD: 2.7,
    d50SedimentBed: 27,
    sigmad50sed: 2.7,
    InnerBankTreeSpecies: "AlnusIncana",
    OuterBankTreeSpecies: "AlnusIncana",
    InnerBankTreeIdealDBH: 1.5,
    OuterBankTreeIdealDBH: 1.7,
    InnerBankTreeIdealHeight: 1,
    OuterBankTreeIdealHeight: 1,
    InnerBankTreeDistance: 32.5,
    OuterBankTreeDistance: 32.5,
    InnerBankTreeDBH: 0.36,
    OuterBankTreeDBH: 0.36,
    InnerBankTreeHeight: 1,
    OuterBankTreeHeight: 1,
    fluidDensity: 1100,
    idealVegetationTimeInterval: 10,
    idealVegetationUseTimeInterval: true,
    idealVegetationUseTreeSize: false,
    erosionCoefficient: Number(2.344596567e-07),
    erosionCoefficientChanged: true,
    criticalShearStressModel: "brownlie",
    criticalShearStressUserDefinedPaOuter: 0.4668128,
    criticalShearStressUserDefinedPaInner: 0.4668128,
    manningCoefficient: 0.05,
    diameterOfLog: 0.36,
    d84BedFactor: 2,
    d90BedFactor: 2.5,
    baseFlow: 0,
    hydrographAscendingFactor: 3.25,
    lengthOfLog: 1,
    calculationName: getTempRunName()
  });*/


  const [bankforNETParameters, setBankforNETParameters] = React.useState({
    returnPeriod: "",
    catchmentArea: "",
    duration: 390,
    discharge: 11.4,
    bankHeightInner: 0.6,
    bankHeightOuter: 0.6,
    channelWidth: 4,
    channelSlope: 0.07,
    innerBankSlope: 29,
    meanChannelInclination: 0.1,
    d50Porosity: 0.3,
    sigmad50sed: 9.8,
    sigmad50nsed: 0.03,
    outerBankSlope: 29,
    bendRadius: 100000,
    d50Inner: 98,
    d50InnerSD: 9.8,
    d50Outer: 98,
    d50OuterSD: 9.8,
    d50SedimentBed: 98,
    InnerBankTreeSpecies: "AlnusIncana",
    OuterBankTreeSpecies: "AlnusIncana",
    InnerBankTreeIdealDBH: 1.5,
    OuterBankTreeIdealDBH: 1.7,
    InnerBankTreeIdealHeight: 1,
    OuterBankTreeIdealHeight: 1,
    InnerBankTreeDistance: 5.35,
    OuterBankTreeDistance: 32.5,
    InnerBankTreeDBH: 0.36,
    OuterBankTreeDBH: 0.36,
    InnerBankTreeHeight: 1,
    OuterBankTreeHeight: 1,
    fluidDensity: 1100,
    idealVegetationTimeInterval: 10,
    idealVegetationUseTimeInterval: true,
    idealVegetationUseTreeSize: false,
    erosionCoefficient: Number(6.698860343828471e-07).toPrecision(4),
    erosionCoefficientChanged: false,
    criticalShearStressModel: "paphitis",
    criticalShearStressUserDefinedPaOuter: 0.4668128,
    criticalShearStressUserDefinedPaInner: 0.4668128,
    manningCoefficient: 0.05,
    diameterOfLog: 0.36,
    d84BedFactor: 2,
    d90BedFactor: 2.5,
    baseFlow: 0,
    hydrographAscendingFactor: 3.25,
    lengthOfLog: 1,
    calculationName: getTempRunName()
  });
  const [showModal, setShowModal] = React.useState(false);
  const [datasets, setDatasets] = React.useState([]);
  const [warningText, setWarningText] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [showMobileInfo, setShowMobileInfo] = React.useState(isMobile);


  function setError(msg) {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setErrorText(msg);
    setDatasets([]);
  }

  function runErosionCalculator() {
    setDatasets([]);
    if(window.createBankForNET === undefined) {
      setWarningText("Scripts still loading... Please try again in a few moments. If this persists, refresh the page.")
      return;
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setShowModal(true);
    setWarningText("");
    setErrorText("");
    ErosionCalculator(bankforNETParameters).then((data)=> {
      setShowModal(false);
      if (data.error !== undefined) {
        console.log(data.errorMsg);
        setErrorText(data.errorMsg);
        return
      }

      setDatasets(data);
      setTabKey("flow");

    })
  }

  function createDownload() {

    let zip = new JSZip();

    const d = new Date();
    console.log(d)
    
    let folder = zip.folder(bankforNETParameters.calculationName);

    // Generate CSV Tables
    function convert_table_as_csv(table_id, separator = ',') {
      // Select rows from table_id
      var rows = document.querySelectorAll('table#' + table_id + ' tr');
      console.log(rows);
      // Construct csv
      var csv = [];
      for (var i = 0; i < rows.length; i++) {
          var row = [], cols = rows[i].querySelectorAll('td, th');
          for (var j = 0; j < cols.length; j++) {
              // Clean innertext to remove multiple spaces and jumpline (break csv)
              var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
              // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
              data = data.replace(/"/g, '""');

              // If number, don't escape it
              const val = parseFloat(data)

              if(!isNaN(val)) {
                row.push(data)
              }
              else {
                // Push escaped string
                row.push('"' + data + '"');
              }
              
          }
          csv.push(row.join(separator));
      }
      var csv_string = csv.join('\n');
      return csv_string
    }

    const tableNames = [ "erosionMetricsAll", "sedimentMetrics", "flowHeightMetrics" ];

    tableNames.forEach(table => {
      const csvData = convert_table_as_csv(table);

      // Add data to the zip file
      folder.file(table + ".csv", csvData);

    })

    // Add inputs parameters to download data (https://github.com/CoSci-LLC/BankforNETWeb/issues/105)
    var input_params = "";
    for (var param in bankforNETParameters)
    {
      input_params += param + "\t=\t" + bankforNETParameters[param] + "\n";
    }

    folder.file("input.params", input_params);

    // Add inputs parameters to download data as csv (https://github.com/CoSci-LLC/BankforNETWeb/issues/133)
    var input_params = "name, value, units\n";

    // Add units to the download (https://github.com/CoSci-LLC/BankforNETWeb/issues/135)
    const param_units = {
      "returnPeriod": "years",
      "InnerBankTreeSpecies": "",
      "OuterBankTreeSpecies": "",
      "idealVegetationUseTimeInterval": "",
      "idealVegetationUseTimeInterval": "",
      "idealVegetationUseTreeSize": "",
      "erosionCoefficientChanged": "",
      "criticalShearStressModel": "",
      "catchmentArea": "km^2",
      "duration": "min",
      "discharge": "m^3/s",
      "bankHeightInner": "m",
      "bankHeightOuter": "m",
      "channelWidth": "m",
      "channelSlope": "m/m",
      "innerBankSlope": "deg",
      "outerBankSlope": "deg",
      "bendRadius": "m",
      "d50Inner": "mm",
      "d50Outer": "mm",
      "d50SedimentBed": "mm",
      "InnerBankTreeIdealDBH": "m",
      "OuterBankTreeIdealDBH": "m",
      "InnerBankTreeIdealHeight": "m",
      "OuterBankTreeIdealHeight": "m",
      "InnerBankTreeDistance": "m",
      "OuterBankTreeDistance": "m",
      "InnerBankTreeDBH": "m",
      "OuterBankTreeDBH": "m",
      "InnerBankTreeHeight": "m",
      "OuterBankTreeHeight": "m",
      "fluidDensity": "km/m^3",
      "idealVegetationTimeInterval": "years",
      "erosionCoefficient": "m/Pa^0.5/s",
      "criticalShearStressUserDefinedPaOuter": "Pa",
      "criticalShearStressUserDefinedPaInner": "Pa",
      "manningCoefficient": "s/m^1/3",
      "diameterOfLog": "m",
      "lengthOfLog": "m",
      "calculationName": ""
    }

    for (var param in bankforNETParameters)
    {
      input_params += param + ", " + bankforNETParameters[param] + ", " + param_units[param] + "\n";
    }

    folder.file("input.csv", input_params);



    const charts = ["flowHeightChart", "transportChart1", "erosionChart"];

    function generateCharts(charts)
    {
      if(charts.length === 0) {
        //We are done!
        zip.generateAsync({type:"blob"}).then(function(content) {
          // see FileSaver.js
          saveAs(content, `${bankforNETParameters.calculationName}.zip`);
        });
        return;
      }
      const chart = charts[0];

      //Get the chart instance
      const chartInstance = Chart.getChart(chart);

      console.log(chartInstance);

      const originalOnComplete = chartInstance.options.animation.onComplete; 


      function onCompleteHandler(chart) {
        // Get just the base64, not the description
        const chartName = chart["chart"].canvas.id;
        const imageBase64 = chart["chart"].toBase64Image("image/png", 1).slice(22)
        chart["chart"].options.animation.onComplete = originalOnComplete; 

        chart["chart"].reset();
        chart["chart"].resize();
        chart["chart"].update();
        chart["chart"].render();

        // Add data to the zip file
        folder.file(chartName + ".png", imageBase64, {base64: true});

        generateCharts(charts.slice(1));
      }

      chartInstance.options.animation.onComplete = onCompleteHandler;

      chartInstance.resize(800, 800)
      chartInstance.update("active");
      chartInstance.render();

      
    }

    generateCharts(charts);    
  }

  return (
    <div>
      <div className="App">
        <Header/>
        <br/>
        <Modal centered show={showModal} backdrop='static'>
          <Modal.Body>
            <div style={{ textAlign: 'center' }}>
              <p>Calculating Erosion... </p>
              <Spinner animation="border"/>
            </div>
          </Modal.Body>

        </Modal>
        <Container fluid="md" >
        <UpdatePWAAlert/>
        {warningText !== "" && <Alert variant="warning">
          {warningText}
          </Alert> }
        {showMobileInfo && <Alert variant="info" dismissible onClose={() => setShowMobileInfo(false) }>
          This website works best in landscape mode on mobile devices
        </Alert>}
       {errorText !== "" && <Alert variant="danger">
                   An issue as occurred: {errorText}
                  </Alert> }
          <Row>
            <Col>
              <Tab.Container mountOnEnter={false} unmountOnExit={false} id="controlTabs" defaultActiveKey={tabKey}  activeKey={tabKey} onSelect={(k) => {setTabKey(k)}}>
                <Nav variant="tabs" className="justify-content-between">
                  <Row>
                  <Nav.Item>
                    <Nav.Link eventKey="input">Input</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="flow" disabled={datasets.length === 0}>Flow Height</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="transport" disabled={datasets.length === 0}>Transport</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="erosion" disabled={datasets.length === 0}>Erosion</Nav.Link>
                  </Nav.Item>
                  </Row>
                  <Row>
                  <Nav.Item>
                    <Nav.Link eventKey="download" disabled={datasets.length === 0}>Download</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="manual">Quick Start Manual</Nav.Link>
                  </Nav.Item>
                  </Row>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="input">
                    <br/>
                    <InputPanel submit={runErosionCalculator} updateParameters={setBankforNETParameters} parameters={bankforNETParameters} setError={setError}/>
                    <br/>
                    <Alert variant="warning">
                        If there is an issue with this software, please report to <a href="https://mailhide.io/e/SfbegYN4" >s......@cosci-llc.com</a>
                  </Alert>
                    {errorText !== "" && <Alert variant="danger">
                      {errorText}
                    </Alert> }
                  </Tab.Pane>
                  <Tab.Pane eventKey="flow">
                    <br/>
                    <FlowHeightTab datasets={datasets} inputs={bankforNETParameters}/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="transport">
                    <br/>
                    <TransportTab datasets={datasets} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="erosion">
                    <br/>
                    <ErosionTab datasets={datasets} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="download">
                    <br/>
                    <Button onClick={createDownload}>
                      Download Graphs!
                    </Button>
                  </Tab.Pane>
                  <Tab.Pane eventKey="manual">
                    <p>
                      Coming soon!
                    </p>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
        <br/>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
