import React from "react";
import Card from "react-bootstrap/Card";
import NumberBox from "../../elements/NumberBox";
import PorosityNumberBox from "../../elements/PorosityNumberBox";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import './ChannelParametersInputOptions.css';

function getSedimentLabelBed() {
  return (
    <span>
      D<span className="supsub">
        <sup>sed</sup>
        <sub>50</sub>
        </span> 
    </span>
  );
}

function getSigmaD50Sed() {
  return (
    <span>
      &sigma;<span className="supsub"><sub>D<span className="supsub">
        <sup>sed</sup>
        <sub>50</sub>
        </span> </sub> </span>
    </span>
  );
}

function getD84BedFactor() {
  return (
    <span>
      D<span className="supsub">
        <sup>sed</sup>
        <sub>84</sub>
        </span>
    </span>
  );
}

function getD90BedFactor() {
  return (
    <span>
      D<span className="supsub">
        <sup>sed</sup>
        <sub>90</sub>
        </span>
    </span>
  );
}

function getSigmaNsed() {
  return (
    <span>
      &sigma;<span className="supsub"><sub>n<span className="supsub">
        <sup>sed</sup>
        </span> </sub> </span>
    </span>
  );
}

function getSedimentLabelBedPorosity() {
  return (
    <span>
      n<span className="supsub">
        <sup>sed</sup>
        </span> 
    </span>
  );
}

function getSedimentLabelBedPorosityInfo() {
  return (
    <span>Sediment porosity of transported sediment (<a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.3929/ethz-a-003823798">Tognacca 1999</a> and <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.1002/9781119952497.ch28">Rickenmann 2014</a>)"</span>
  );
}

function getD84BedFactorInfo() {
  return (
    <span>Multiplying factor to compute {getD84BedFactor()} from {getSedimentLabelBed()}</span>
  );
}

function getD90BedFactorInfo() {
  return (
    <span>Multiplying factor to compute {getD90BedFactor()} from {getSedimentLabelBed()}</span>
  );
}

function getManningCoefficientLabel() {
 // m^(1/3)/s
  return (
    <span>
      s/m<span style={{ fontSize: "0.7em", verticalAlign: "top" }}>1/3</span>
    </span>
  );
}


export default function TreeInputOptions(props) {
  const [showModal, setShowModal] = React.useState(false);
  const parameters = props.parameters;
  const handleTextBoxOnChange = props.handleTextBoxOnChange;

  return (
    <Card>
      <Card.Header>
        Channel Parameters{" "}
        <Button tabIndex="-1" variant="light" className="d-inline-flex align-items-center" onClick={() => setShowModal(true)}> 
          <FontAwesomeIcon icon={faInfoCircle} />
        </Button>
      </Card.Header>
      <Card.Body>
      <Modal centered show={showModal} size="lg" onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            Channel Parameters Help
          </Modal.Header>
          <Modal.Body>
            <div>
              <h2>Channel Parameter Diagram</h2>
              <img width="100%" src="/images/bankfornet_fig1.png"/>
              <hr/>
              <h2>Tree Parameter Diagram</h2>
              <img width="100%" src="/images/bankfornet_fig2.png"/>
            </div>
          </Modal.Body>

        </Modal>
        <Container>
        <Row>
            <Col sm={12}>
              <NumberBox
                label="Channel Width"
                unit="m"
                step="0.1"
                name="channelWidth"
                value={parameters.channelWidth}
                onChange={handleTextBoxOnChange}
              />
            </Col>
          </Row>
          <Row>
            <Col md={5} sm={12}>
              <NumberBox
                label="Channel Slope"
                step="0.001"
                unit="m/m"
                name="channelSlope"
                value={parameters.channelSlope}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={7} sm={12}>
              <NumberBox
                label="Mean Channel Inclination"
                unit="m/m"
                step="0.001"
                info="Average inclination of upstream section of the stream within 100-300 m; Used to calculate transport capacity of debris flows"
                name="meanChannelInclination"
                value={parameters.meanChannelInclination}
                onChange={handleTextBoxOnChange}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="Inner Bank Slope"
                unit="deg"
                name="innerBankSlope"
                value={parameters.innerBankSlope}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label="Outer Bank Slope"
                unit="deg"
                name="outerBankSlope"
                value={parameters.outerBankSlope}
                onChange={handleTextBoxOnChange}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="Bend Radius"
                unit="m"
                step="0.1"
                name="bendRadius"
                value={parameters.bendRadius}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label="Manning Coefficient"
                controlId="manningCoefficient"
                unit={getManningCoefficientLabel()}
                step="0.01"
                name="manningCoefficient"
                value={parameters.manningCoefficient}
                onChange={handleTextBoxOnChange}
                required={false}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label="Inner Bank Height"
                controlId="bankHeightInner"
                unit="m"
                step="0.01"
                info="Used only for calculating Root Area Ratio. If left blank, Catchment Area will be used to calculate bank height"
                name="bankHeightInner"
                value={parameters.bankHeightInner}
                onChange={handleTextBoxOnChange}
                required={false}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label="Outer Bank Height"
                controlId="bankHeightOuter"
                unit="m"
                step="0.01"
                info="Used only for calculating Root Area Ratio. If left blank, Catchment Area will be used to calculate bank height"
                name="bankHeightOuter"
                value={parameters.bankHeightOuter}
                onChange={handleTextBoxOnChange}
                required={false}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label={getSedimentLabelBed()}
                controlId="d50SedimentBed"
                info="Mean sediment diameter for transported sediments"
                unit="mm"
                name="d50SedimentBed"
                value={parameters.d50SedimentBed}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
             <NumberBox
                label={getSigmaD50Sed()}
                controlId="sigmad50sed"
                info="Standard deviation for sediment diameter"
                unit="mm"
                step="0.0001"
                name="sigmad50sed"
                value={parameters.sigmad50sed}
                onChange={handleTextBoxOnChange}
              /> 
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              <NumberBox
                label={getD84BedFactor()}
                controlId="d84BedFactor"
                info={getD84BedFactorInfo()}
                unit="-"
                name="d84BedFactor"
                value={parameters.d84BedFactor}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
             <NumberBox
                label={getD90BedFactor()}
                controlId="d90BedFactor"
                info={getD90BedFactorInfo()}
                unit="-"
                step="0.0001"
                name="d90BedFactor"
                value={parameters.d90BedFactor}
                onChange={handleTextBoxOnChange}
              /> 
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={12}>
              { /* n_sed */}
              <PorosityNumberBox
                label={getSedimentLabelBedPorosity()}
                controlId="d50Porosity"
                info={getSedimentLabelBedPorosityInfo()}
                unit="-"
                step="0.0001"
                name="d50Porosity"
                value={parameters.d50Porosity}
                onChange={handleTextBoxOnChange}
              />
            </Col>
            <Col md={6} sm={12}>
              <NumberBox
                label={getSigmaNsed()}
                controlId="sigmad50nsed"
                info="Standard deviation for sediment porosity"
                unit="-"
                step="0.0001"
                name="sigmad50nsed"
                value={parameters.sigmad50nsed}
                onChange={handleTextBoxOnChange}
              /> 
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}
